import React from "react";
import BuzzrIcon from "../icons/BuzzrIcon";
import LightningBGIcon from "../icons/LightningBGIcon";
import BuzzrQRCode from "../icons/BuzzrQRCode";
import FremantleLogo from "../icons/FremantleLogo";
import MicrosoftLogo from "../icons/MicrosoftLogo";
import NVIDIALogo from "../icons/NVIDIALogo";

export default function Home() {
	return (
		<div className="home">
			<LightningBGIcon className="top" />
			<BuzzrIcon />
			<BuzzrQRCode />
			<div className="title">
				SCAN TO PLAY<br/>
				PLAY TO WIN
			</div>
			<div className="text">
			Play along with your favorite game shows<br />in real time! An interactive experience<br/>brought to you by: 
			</div>
			<div className="partners">
				<a href="https://microsoft.com" rel="noopener noreferrer" target="_blank"><MicrosoftLogo /></a>
				<a href="https://fremantle.com" rel="noopener noreferrer" target="_blank"><FremantleLogo /></a>
				<a href="https://nvidia.com" rel="noopener noreferrer" target="_blank"><NVIDIALogo /></a>
			</div>
			<LightningBGIcon className='bottom' />
		</div>
	)
}