import React, { memo } from 'react';
import { getAdditionalInfos } from '../utils/getAdditionalInfos';
import Avatar from '../../ui/Avatar';
import { useOtherPlayerProfile } from '../../../hooks/useOtherPlayer';

const timeFormat = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' });

const titleCase = (s) =>
	s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())  

function DisplayName({ playFabId }) {
	const otherPlayer = useOtherPlayerProfile(playFabId);
	
	return (
		otherPlayer?.DisplayName && <div className="displayname">{otherPlayer?.DisplayName}</div>
	)
}

function RealtimeEvent({ e }:{ e:RealtimeEvent }) {

	return (
		<div className="event">
			<div className="time">{timeFormat.format(new Date(e.Timestamp)).replace(':','h')}</div>
			<div className="content">
				{e.EntityType !== 'title' && <Avatar playerId={e.EntityId} />}
				{e.EntityType === 'title' && <Avatar />}
				
				<div className="info">
					{e.EntityType !== 'title' && <DisplayName playFabId={e.EntityId} />}
					<div className="name">{titleCase(e.EventName)}</div>
					<div className="additional-infos">{getAdditionalInfos(e)}</div>
				</div>
			</div>
		</div>
	);
}

export default memo(RealtimeEvent);