import React from 'react';
import usePlayer from '../../hooks/usePlayer';
import LoadingSpinner from '../icons/LoadingSpinner';
import { useOtherPlayerProfile } from '../../hooks/useOtherPlayer';
import { CDN_BASE } from '../../Constants';

export default function Avatar({ playerId }:{ playerId?: string }) {
	const { AvatarUrl } = usePlayer();
	const otherPlayer = useOtherPlayerProfile(playerId);
	const defaultAvatar = CDN_BASE + 'profile_default.png';

	const toShow = otherPlayer?.AvatarUrl || AvatarUrl || defaultAvatar;
	const showLoading = (playerId && !otherPlayer) || !toShow;

	return (
		<div className="avatar">
			<div className="image-ctn">
				{!showLoading && <img src={toShow} width="100" height="100" alt="" />}
				{showLoading && <LoadingSpinner className="spinner" />}
			</div>
		</div>
	);
}