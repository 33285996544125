import React, { useEffect, useRef, useState } from 'react';
import useRealtimeConnection from '../../api/react/hooks/useRealtimeConnection';
import { API_CONFIG } from '../../Constants';
import usePlayer from '../../hooks/usePlayer';
import { ANY_EVENT } from '../../api/Realtime';
import './index.scss';
import RealtimeEvent from './components/RealtimeEvent';
import { getXrApi } from '../../api/apiBridge';
import XRLogo from '../icons/XRLogo';

const LS_KEY = 'realtime-visualizer';

export default function RealtimeVisualizer() {
	const {
		playerId,
	} = usePlayer();

	const messagesRef = useRef<HTMLPreElement>(null);

	const [events, setEvents] = useState<RealtimeEvent[]>(JSON.parse(localStorage.getItem(LS_KEY + '_' + playerId) || '[]'));

	useEffect(() => {
		localStorage.setItem(LS_KEY + '_' + playerId, JSON.stringify(events));
		requestAnimationFrame(() => {
			if (messagesRef.current) {
				messagesRef.current.scrollTo({
					behavior: 'smooth',
					top: messagesRef.current.scrollHeight,
				});
			}
		});
	}, [events, playerId]);

	function clearEvents() {
		setEvents([]);
		localStorage.setItem(LS_KEY + '_' + playerId, JSON.stringify([]));
	}
	
	const {
		addListener,
		removeListener,
	} = useRealtimeConnection(
		API_CONFIG.realtime.apiUrl,
		API_CONFIG.playfab.appId,
		playerId,
		getXrApi().GetSessionTicket(),
		[
			'playstream/' + API_CONFIG.playfab.appId,
			'playstream/' + API_CONFIG.playfab.appId + '/{id}'
		]
	);

	useEffect(() => {
		const onEvent = (m => {
			setEvents(prev => ([ ...prev, m ].slice(-100)));
		});
		addListener(ANY_EVENT, onEvent);

		return () => {
			removeListener(ANY_EVENT, onEvent);
		};
	}, [addListener, removeListener]);
	
	return (
		<div className="realtime-visualizer">
			<div className="top">
				<div className="title"><XRLogo /></div>
			</div>
			{events && events.length > 0 && <pre className="messages" ref={messagesRef}>
				{events.map((e) => (
					<RealtimeEvent e={e} key={e.EventId} />
				))}
			</pre>}
			{!events || events.length === 0 && <div className="no-events">No events yet</div>}
			<div className="footer">Live PlayStream</div>
		</div>
	);
}