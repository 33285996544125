import React from 'react';

export function getAdditionalInfos(event:RealtimeEvent) {
	let dir;
	switch (event.EventName) {
		case 'player_logged_in':
			return `${event.Location.City}, ${event.Location.CountryCode}`;
		case 'player_rule_executed':
			return event.DisplayName;
		case 'player_executed_cloudscript':
		case 'player_triggered_action_executed_cloudscript':
			return <>{event.FunctionName} {event?.CloudScriptExecutionResult?.Error ? '❌' : '✅'}</>;
		case 'player_action_executed':
			return event.ActionName;
		case 'player_entered_segment':
		case 'player_left_segment':
			return event.SegmentName;
		case 'player_statistic_changed':
			dir = event.StatisticValue > event.StatisticPreviousValue ? '📈' : '📉';
			return <><strong>{event.StatisticName}</strong>: {event.StatisticPreviousValue || 'not set'} to {event.StatisticValue} {dir}</>;
		case 'player_consumed_item':
		case 'player_inventory_item_added':
			return `${event.DisplayName}`;
		case 'player_virtual_currency_balance_changed':
			dir = event.VirtualCurrencyBalance > event.VirtualCurrencyPreviousBalance ? '📈' : '📉';
			return `${event.VirtualCurrencyName}: ${event.VirtualCurrencyPreviousBalance} > ${event.VirtualCurrencyBalance} ${dir}`;
		case 'player_displayname_changed':
			return <><strong>{event.PreviousDisplayName}</strong> to <strong>{event.DisplayName}</strong></>;
		// case 'player_changed_avatar':
		// 	return event.ImageUrl ? <img width="50" height="50" src={event.ImageUrl} alt="" /> : '';
		case 'notifications':
		case 'player_notification_pushed':
			return `${event.Message}`;
		case 'store':
		case 'player_store_cleared':
			break;
		case 'player_objective_progress':
		case 'player_objective_completed':
		case 'player_mission_completed':
			return `Mission: ${event.MissionId}\n${event.ObjectiveId ? `Objective: ${event.ObjectiveId}` : ''}`;
	}
	return null;
}