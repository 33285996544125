import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';

export const getGlobalVariables = createDebouncedAsyncAction(
	'playfab-xr/getGlobalVariables',
	xrAction(() => {
		return getXrApi().Client.GetGlobalVariable();
	}),
);

const initialState = {
	isLoaded: false,
	leaderboardUrl: '',
	CompoundStatsConfig: {} as ({ source: { name: string, mapping: number[] }, target: { name: string, mapping: number[] } }[] | null),
};

const globalVariables = createSlice({
	name: 'globalVariables',
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getGlobalVariables.actions.fulfilled, (state, action) => {
			return {
				...state,
				isLoaded: true,
				...action.payload.data.GlobalVariables.reduce((acc, curr) => {
					acc[curr.dataKey] = curr.dataVal;
					return acc;
				}, {} as Record<string, any>),
			};
		});
	},
	initialState,
});

export default globalVariables;
