import React, { memo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ROUTES } from './Constants';
import '../css/index.scss';
import usePlayFab from './hooks/usePlayFab';
import useRealtime from './hooks/useRealtime';
import Login from './components/routes/Login';
import usePlayerStatistics from './hooks/usePlayerStatistics';
import { useAppSelector } from './redux/config/store';
import RealtimeVisualizer from './components/RealtimeVisualizer';
import Home from './components/routes/Home';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function App() {
	/*
	* Initialize PlayFab XR connection (once logged in, we fetch all the player data, you should)
	* only use this hook once, and only at the root of your app.
	*/
	const { playerId, isLoaded } = usePlayFab();
	const { hasLoaded } = usePlayerStatistics();

	// Connect to realtime if the service is active in this app
	// useRealtime();

	const isLogin = useMatch(ROUTES.ROOT + ROUTES.LOGIN);
	const showLogin = !playerId && !isLogin;

	const lang = useAppSelector((state) => state.env.Lang);

	return (
		<div className={lang + (isSafari ? ' safari' : '')}>
			{showLogin && <Navigate to={ROUTES.LOGIN} replace={true} />}
			<Routes>
				<Route path={ROUTES.ROOT}>
					<Route index element={isLoaded && hasLoaded && <Home />} />
					<Route path={ROUTES.LOGIN} element={<Login />} />
				</Route>
			</Routes>
			<RealtimeVisualizer />
		</div>
	);
}

export default memo(App);
